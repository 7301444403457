<script>
import Swal from "sweetalert2";
import axios from "axios";
import { lien } from "/src/assets/api.js";
export default {
  name: "UploadBordereauPaiement",
 
  data() {
    return {
      bordereau:{},
      amount:"",
      typeOperation:"",
      state:"en attente",
      spinner:false,
      numero:"",
      moyensPayment:[],
      modePayment:"",
    };
  },
  methods: {
    chooseFile(file){
        this.bordereau = file.target.files[0]
       
    },
    middleware(){
      let patternAmount = /^\d/
      if(!patternAmount.test(this.amount)) {
        Swal.fire({
              text: "Veuillez renseignez des chiffres",
              icon: "info",
              showConfirmButton:true,
          
            });
      }else{
        this.PosterbordereauPaiement()
      }
      
    },
    PosterbordereauPaiement() {
      this.spinner = true
        let dataSend = new FormData();
        dataSend.append("proof", this.bordereau ? this.bordereau:null);
      dataSend.append("moyen_id", this.modePayment);
      dataSend.append("amount", this.amount);
      dataSend.append("numero", this.user.phone);
      dataSend.append("state", this.state);
      dataSend.append("type", this.typeOperation);
         axios
        .post(lien + "/api/operations",dataSend)
        .then((reponse) => {
          
          if (reponse.data.status === true) {
            this.spinner = false
            Swal.fire({
              text: "Envoi réussi.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            setTimeout(() => {
        this.amount = ""
       this.state = ""
      this.typeOperation = ""
      this.modePayment = ""
            }, 1500);
          }
          if (reponse.data.status === false) {
            this.spinner = false
            Swal.fire({
              text: reponse.data.message,
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
         
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
              text: "Contactez votre service informatique.",
              icon: "info",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
            });
            this.spinner = false
        });
    },
    async getListMoyensPayment(){

      try{
       const response =  await axios.get(lien + "/api/moyens", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
      //  console.log(response)
        this.moyensPayment = response.data.data

      }catch(error){
        console.log(error)
      }
     
    }
  
  },
  created() {
    this.user = this.$store.state.user;
   this.getListMoyensPayment()
  }
 
};
</script>
<template>
    <div class="page-wrapper">
      <div class="container-fluid">
        <div class="row page-titles">
          <div class="col-md-5 align-self-center"></div>
          <div class="col-md-7 align-self-center text-end">
            <div class="d-flex justify-content-end align-items-center">
              <ol class="breadcrumb justify-content-end">
                <li class="fw-bold h3"><span>Transactions</span></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section class="spinnerWindow" v-show="spinner">
        <div class="spinner" >
          <div class="spinner-border text-warning" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
       </section>
  
      <form @submit.prevent="middleware">
        <div class="form-body">
          <div class="card-body">
            <div class="row pt-3">


                <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Type de l'operation</label>
                      <select name="" id="" class="w-100 form-group types"
                      v-model="typeOperation"
                      required>
                      <option value="" disabled>-----Choisir le type de l'operation-----</option>
                        <option value="retrait">Retrait</option>
                        <option value="depot">Dépôt</option>
                      </select>
                    
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Moyens de paiement</label>
                      <select name="" id="" class="w-100 form-group types"
                      v-model="modePayment"
                      required>
                      <option value="" disabled>-----Choisir un moyen de paiement-----</option>
                        <option
                         :value="item.id" 
                         v-for="(item,index) in moyensPayment"
                         :key="index"
                        >{{item.libelle}}</option>
                    
                      </select>
                    
                    </div>
                  </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">Montant (Fcfa)</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="amount"
                  />     
                </div>
              </div>
              <div class="col-md-6" v-if="typeOperation == 'depot'">
                <div class="form-group">
                  <label class="form-label">Pièce jointe</label>
                  <input
                    type="file"
                    class="form-control"
                    @change.prevent="chooseFile"
                    required
                  />     
                </div>
              </div>
  
       
            </div>
           
          </div>
          <div class="form-actions">
            <div class="card-body">
              <button class="btn text-white mx-3 btn-envoyer" type="submit">
                envoyer
              </button>
              <button @click="$router.go(-1)" type="button" class="btn btn-dark">
                Retour
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
 
  <style scoped>
  * {
    font-weight: bold !important;
  }
  .spinner{
    background-color:rgb(27, 27, 27) !important;
    width:45px;
    height:45px;
    padding:.5em 0 0 0 ;
    border-radius:5px;
  }
  .spinnerWindow {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(103, 101, 101, 0.295);
    z-index: 99;
    display: flex;
    place-items: center;
    justify-content: center;
  }
  .my_footer {
    position: relative;
    width: 100%;
    bottom: -9em;
    margin-left: 0 !important;
  }
  select {
    padding: 0.589em !important;
    border-radius: 0.25rem;
  }
  select:active {
    border: 1px solid rgb(134, 134, 134);
  }
  .btn-envoyer {
    background: rgb(231, 202, 15) !important;
    color: black !important;
    border: 3px solid black !important;
  }
  input,
  select {
    border: 1px solid black !important;
  }
  .form-group {
    text-align: left !important;
  }

  </style>
  